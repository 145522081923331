import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Home'
import {grpc} from "@improbable-eng/grpc-web";

// Import code-generated data structures.
import {BookService} from "./my-generated-code/book_service_pb_service";
import {GetBookRequest} from "./my-generated-code/book_service_pb";
import {StreamRequest} from "./my-generated-code/book_service_pb";
import {CardService} from "./_proto/aionio/deck/card_service_pb_service";

import { CookiesProvider } from 'react-cookie';

const getBookRequest = new GetBookRequest();
getBookRequest.setIsbn(60929871);
// const client = grpc.client(BookService.GetBook, {
//   host: "https://api.aion.io",
//   // host: "http://localhost:9090",
//   transport: grpc.WebsocketTransportFactory
// })
// client.onMessage((message: Book) => {
//   console.log("onMessage", message);
// });
// client.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata) => {
//   console.log("onEnd", status, statusMessage, trailers);
// });
// client.start(new grpc.Metadata({"HeaderTestKey1": "ClientValue1"}));
// client.send(getBookRequest);
// client.finishSend();

// const streamRequest = new StreamRequest();
// let today = new Date();
// streamRequest.setMessage("my name" + today.getSeconds());
// const client2 = grpc.client(BookService.Chat, {
//   // host: "https://api.aion.io",
//   host: "http://localhost:9090",
//   transport: grpc.WebsocketTransportFactory
// })
// client2.onMessage((message: Book) => {
//   console.log("onMessage", message);
// });
// client2.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata) => {
//   console.log("onEnd", status, statusMessage, trailers);
// });
// client2.start(new grpc.Metadata({"HeaderTestKey1": "ClientValue1"}));
// client2.send(streamRequest);
// streamRequest.setMessage("first message")
// client2.send(streamRequest);
// grpc.unary(BookService.GetBook, {
//   request: getBookRequest,
//   host: "https://api.aion.io",
//   onEnd: res => {
//     const { status, statusMessage, headers, message, trailers } = res;
//     console.log(status)
//     console.log(statusMessage)
//     console.log(headers)
//     console.log(message)
//     if (status === grpc.Code.OK && message) {
//       console.log("all ok. got book: ", message.toObject());
//     }
//   }
// });

function App() {
  return (
    <CookiesProvider>
      <div className="App">
        <header className="App-header">
          <Home url={window.location.href}/>
        </header>
      </div>
    </CookiesProvider>
  );
}

export default App;
