import React, { Component } from 'react'
import CreateGame from './CreateGame'
import CreateUser from './CreateUser'
import PlayGame from './PlayGame'
import CommunityGroup from './CommunityGroup'
import CardComponent from './CardComponent'
import {grpc} from "@improbable-eng/grpc-web";

import {BookService} from "./my-generated-code/book_service_pb_service";
import {GetBookRequest} from "./my-generated-code/book_service_pb";
import {StreamRequest} from "./my-generated-code/book_service_pb";
import {CardService} from "./_proto/aionio/deck/card_service_pb_service";
import {GameService} from "./_proto/aionio/game/game_service_pb_service";
import {PlayerActionRequest} from "./_proto/aionio/game/game_service_pb";
import {PlayerActionResponse} from "./_proto/aionio/game/game_service_pb";

import {CommunityGroupService} from "./_proto/aionio/communitygroup/community_group_service_pb_service";
import {GetCommunityGroupsRequest} from "./_proto/aionio/communitygroup/community_group_service_pb";

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class Home extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      url: this.props.url,
      response: 'Original',
      count: 0,
      images: [],
      flop: null,
      client: null,
      name: cookies.get('name') || 'Jeff'
    };
  }

  loadImages = images => {
    let arr = []
    let promises = []
    images.forEach(element => {
      // arr.push(`./cards/${element.toUpperCase()}.svg`)
      promises.push(import(`./cards/${element.toUpperCase()}.svg`))
      // .then(image => {
      //   arr.push(AS)
      //   console.log('image.default')
      //   console.log(AS)
      //   console.log(image)
      //   console.log(typeof image.default)
      // });
    });
    Promise.all(promises).then(values => {
      arr = values.map(value => 
        value.default
      );
      this.setState({
        images: arr
      })
    })
    console.log("in jeff loadimages")
    console.log(arr)
    console.log(typeof arr)
    console.log(typeof arr[0])
    console.log("slice")
    console.log(typeof arr.slice)
    if (this.state.count === 0) {
      console.log("updating state")
      this.setState({
        count: 1,
        foobar: arr
      });
    }
    
  }

  listImages = images => {
    images.map((image) => 
      <img src={image} alt=''/>
    )
  }

  componentDidMount() {
    const getBookRequest = new GetBookRequest();
    getBookRequest.setIsbn(60929871);
    grpc.unary(BookService.GetBook, {
      request: getBookRequest,
      host: 'https://api.aion.io',
      // host: 'http://localhost:9090',
      transport: grpc.CrossBrowserHttpTransport({ withCredentials: 'include' }),
      onEnd: res => {
        const { status, statusMessage, headers, message, trailers } = res;
        if (status === grpc.Code.OK && message) {
          console.log("all ok. got book: ", message.toObject());
        }
      }
    });
    console.log("in componentDidMount");
    const playerActionRequest = new PlayerActionRequest();
    let today = new Date();
    playerActionRequest.setMessage("my name" + today.getSeconds());
    const client2 = grpc.client(GameService.PlayerAction, {
      host: "https://api.aion.io",
      // host: "http://localhost:9090",
      transport: grpc.WebsocketTransport({ withCredentials: 'include' })
    })
    client2.onMessage((message: PlayerActionResponse) => {
      console.log("onMessage", message.toObject());
      let arr = [];
      let promises = [];
      promises.push(import(`./cards/${message.toObject().message.toUpperCase()}.svg`));
      Promise.all(promises).then(values => {
        arr = values.map(value => 
          value.default
        );
        this.setState({
          images: arr
        })
      });
    });
    client2.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata) => {
      console.log("onEnd", status, statusMessage, trailers);
    });
    const { cookies } = this.props;
    // client2.start(new grpc.Metadata({"name": cookies.get('name')}));
    console.log("before client2.start")
    client2.start(new grpc.Metadata({"name": "TESTNAME"}));
    console.log("before client2.send")
    client2.send(playerActionRequest);
    console.log("after client2.send")
    let playerActionRequest2 = new PlayerActionRequest();
    playerActionRequest2.setMessage("first message")
    client2.send(playerActionRequest2);
    this.setState({
      client: client2
    });
    // client2.finishSend();
    // fetch("https://api.aion.io")
    //   .then(response => {
    //     return response.text();
    //   })
    //   .then(data => {
    //     this.setState({
    //       response: data
    //     });
    //   },
    //   error => {
    //     this.setState({
    //       response: error
    //     })
    //   })
    // const socket = new WebSocket('wss://api.aion.io/websocket');
    // // const socket = new WebSocket('ws://localhost:9090/websocket');
    // socket.addEventListener('open', function (event) {
    //   socket.send('Hello Server!');
    // });
    // socket.addEventListener('message', (event) => {
    //   console.log('Message from server:', event.data);
    //   this.loadImages(JSON.parse(event.data))
    //   // this.setState({
    //   //   images: JSON.parse(event.data)
    //   // });
    // });

    const getCommunityGroupsRequest = new GetCommunityGroupsRequest();
    grpc.unary(CommunityGroupService.GetCommunityGroups, {
      request: getCommunityGroupsRequest,
      host: 'https://api.aion.io',
      // host: 'http://localhost:9090',
      transport: grpc.CrossBrowserHttpTransport({ withCredentials: 'include' }),
      onEnd: res => {
        const { status, statusMessage, headers, message, trailers } = res;
        if (status === grpc.Code.OK && message) {
          console.log("getCommunityGroups Success. received: ", message.toObject());
        }
      }
    })
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let streamRequest = new StreamRequest();
      streamRequest.setMessage(e.target.value);
      this.state.client.send(streamRequest);
      const { cookies } = this.props;
      var name = e.target.value;
      cookies.set('name', name, { path: '/' });
      this.setState({ name });
    }
  }

  render() {
    // {this.state.foobar.map((image, index) =>
    //   <CardComponent src={image} key={index}/>
    // )}
    let page;
    if (window.location.pathname == "/") {
      page = <CreateUser />
    } else {
      page = <PlayGame />
    }

    page = <CommunityGroup description="this is the community group desc" />

    return (
      page
    )
  }
}

export default withCookies(Home);