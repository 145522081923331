import React, { Component } from 'react'
import {grpc} from "@improbable-eng/grpc-web";

import {BookService} from "./my-generated-code/book_service_pb_service";
import {GetBookRequest} from "./my-generated-code/book_service_pb";
import {StreamRequest} from "./my-generated-code/book_service_pb";
import {CardService} from "./_proto/aionio/deck/card_service_pb_service";
import {GameService} from "./_proto/aionio/game/game_service_pb_service";

import {CommunityGroupService} from "./_proto/aionio/communitygroup/community_group_service_pb_service";
import {JoinCommunityGroupRequest} from "./_proto/aionio/communitygroup/community_group_service_pb";

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class CommunityGroup extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      busyIndicator: false,
      communityGroupId: props.CommunityGroupId,
      description: "description",
      firstName: '',
      lastName: '',
      email: '',
    };
  }

//  componentDidMount() {
//  }

  _handleSubmit = (e) => {
    e.preventDefault();
    this.setState({busyIndicator: true});
    const joinCommunityGroupRequest = new JoinCommunityGroupRequest();
    joinCommunityGroupRequest.setFirstname(this.state.firstName);
    joinCommunityGroupRequest.setLastname(this.state.lastName);
    joinCommunityGroupRequest.setEmail(this.state.email);
    joinCommunityGroupRequest.setPhonenumber("1234567890");
    // joinCommunityGroupRequest.setCommunitygroupid(this.state.communityGroupId);
    joinCommunityGroupRequest.setCommunitygroupid(1943805);
    grpc.unary(CommunityGroupService.JoinCommunityGroup, {
      request: joinCommunityGroupRequest,
      host: 'https://api.aion.io',
      // host: 'http://localhost:9090',
      transport: grpc.CrossBrowserHttpTransport({ withCredentials: 'include' }),
      onEnd: res => {
        const { status, statusMessage, headers, message, trailers } = res;
        if (status === grpc.Code.OK && message) {
          console.log("Success. received: ", message.toObject());
        }
        this.setState({busyIndicator: false});
      }
    })
  }

  _handleChangeFirstName = (e) => {
    e.preventDefault();
    this.setState({firstName: e.target.value});
  }

  _handleChangeLastName = (e) => {
    e.preventDefault();
    this.setState({lastName: e.target.value});
  }

  _handleChangeEmail = (e) => {
    e.preventDefault();
    this.setState({email: e.target.value});
  }

  render() {
    return (
      <>
      {this.state.description}
      <form className="col-sm-11" onSubmit={this._handleSubmit}>
        <div className="d-flex p-2 align-items-center">
          <label for="FirstNameInput" className="col-sm-3 col-form-label">First Name</label>
          <input type="text" className="col-sm-9 form-control align-middle" id="FirstNameInput" value={this.state.firstName} onChange={this._handleChangeFirstName}/>
        </div>
        <div className="d-flex p-2 align-items-center">
          <label for="LastNameInput" className="col-sm-3 form-label">Last Name</label>
          <input type="text" className="col-sm-9 form-control" id="LastNameInput" value={this.state.lastName} onChange={this._handleChangeLastName}/>
        </div>
        <div className="d-flex p-2 align-items-center">
          <label for="EmailInput" className="col-sm-3 form-label">Email</label>
          <input type="text" className="col-sm-9 form-control" id="LastNameInput" value={this.state.email} onChange={this._handleChangeEmail}/>
        </div>
        <button disabled={this.state.busyIndicator} type="submit" className="btn btn-primary">
          {this.state.busyIndicator ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Sign Up"}
        </button>
      </form>
      </>
    )
  }
}

export default withCookies(CommunityGroup);