/* eslint-disable */
// package: aionio.deck
// file: aionio/deck/card_service.proto

var aionio_deck_card_service_pb = require("../../aionio/deck/card_service_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var CardService = (function () {
  function CardService() {}
  CardService.serviceName = "aionio.deck.CardService";
  return CardService;
}());

CardService.GetCard = {
  methodName: "GetCard",
  service: CardService,
  requestStream: false,
  responseStream: false,
  requestType: aionio_deck_card_service_pb.GetCardRequest,
  responseType: aionio_deck_card_service_pb.CardResponse
};

exports.CardService = CardService;

function CardServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

CardServiceClient.prototype.getCard = function getCard(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(CardService.GetCard, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.CardServiceClient = CardServiceClient;

