/* eslint-disable */
// source: aionio/communitygroup/community_group_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.aionio.communitygroup.GetCommunityGroupsRequest', null, global);
goog.exportSymbol('proto.aionio.communitygroup.GetCommunityGroupsResponse', null, global);
goog.exportSymbol('proto.aionio.communitygroup.JoinCommunityGroupRequest', null, global);
goog.exportSymbol('proto.aionio.communitygroup.JoinCommunityGroupResponse', null, global);
goog.exportSymbol('proto.aionio.communitygroup.PlanningCenterData', null, global);
goog.exportSymbol('proto.aionio.communitygroup.PlanningCenterGroup', null, global);
goog.exportSymbol('proto.aionio.communitygroup.PlanningCenterGroupAttributes', null, global);
goog.exportSymbol('proto.aionio.communitygroup.PlanningCenterGroupRelationships', null, global);
goog.exportSymbol('proto.aionio.communitygroup.PlanningCenterTypeId', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.JoinCommunityGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.JoinCommunityGroupRequest.displayName = 'proto.aionio.communitygroup.JoinCommunityGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.PlanningCenterData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.PlanningCenterData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.PlanningCenterData.displayName = 'proto.aionio.communitygroup.PlanningCenterData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.PlanningCenterTypeId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.PlanningCenterTypeId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.PlanningCenterTypeId.displayName = 'proto.aionio.communitygroup.PlanningCenterTypeId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.PlanningCenterGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.PlanningCenterGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.PlanningCenterGroup.displayName = 'proto.aionio.communitygroup.PlanningCenterGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.PlanningCenterGroupAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.PlanningCenterGroupAttributes.displayName = 'proto.aionio.communitygroup.PlanningCenterGroupAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.PlanningCenterGroupRelationships, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.PlanningCenterGroupRelationships.displayName = 'proto.aionio.communitygroup.PlanningCenterGroupRelationships';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.JoinCommunityGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.JoinCommunityGroupResponse.displayName = 'proto.aionio.communitygroup.JoinCommunityGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.aionio.communitygroup.GetCommunityGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.GetCommunityGroupsRequest.displayName = 'proto.aionio.communitygroup.GetCommunityGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.aionio.communitygroup.GetCommunityGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.aionio.communitygroup.GetCommunityGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.aionio.communitygroup.GetCommunityGroupsResponse.displayName = 'proto.aionio.communitygroup.GetCommunityGroupsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.JoinCommunityGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.JoinCommunityGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    communitygroupid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.JoinCommunityGroupRequest;
  return proto.aionio.communitygroup.JoinCommunityGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.JoinCommunityGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCommunitygroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.JoinCommunityGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.JoinCommunityGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCommunitygroupid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest} returns this
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lastName = 2;
 * @return {string}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest} returns this
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest} returns this
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phoneNumber = 4;
 * @return {string}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest} returns this
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 communityGroupId = 5;
 * @return {number}
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.getCommunitygroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupRequest} returns this
 */
proto.aionio.communitygroup.JoinCommunityGroupRequest.prototype.setCommunitygroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.PlanningCenterData.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.PlanningCenterData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.PlanningCenterData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterData.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.aionio.communitygroup.PlanningCenterTypeId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.PlanningCenterData}
 */
proto.aionio.communitygroup.PlanningCenterData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.PlanningCenterData;
  return proto.aionio.communitygroup.PlanningCenterData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.PlanningCenterData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.PlanningCenterData}
 */
proto.aionio.communitygroup.PlanningCenterData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.aionio.communitygroup.PlanningCenterTypeId;
      reader.readMessage(value,proto.aionio.communitygroup.PlanningCenterTypeId.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.PlanningCenterData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.PlanningCenterData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.PlanningCenterData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.aionio.communitygroup.PlanningCenterTypeId.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlanningCenterTypeId data = 1;
 * @return {?proto.aionio.communitygroup.PlanningCenterTypeId}
 */
proto.aionio.communitygroup.PlanningCenterData.prototype.getData = function() {
  return /** @type{?proto.aionio.communitygroup.PlanningCenterTypeId} */ (
    jspb.Message.getWrapperField(this, proto.aionio.communitygroup.PlanningCenterTypeId, 1));
};


/**
 * @param {?proto.aionio.communitygroup.PlanningCenterTypeId|undefined} value
 * @return {!proto.aionio.communitygroup.PlanningCenterData} returns this
*/
proto.aionio.communitygroup.PlanningCenterData.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.aionio.communitygroup.PlanningCenterData} returns this
 */
proto.aionio.communitygroup.PlanningCenterData.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.aionio.communitygroup.PlanningCenterData.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.PlanningCenterTypeId.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.PlanningCenterTypeId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.PlanningCenterTypeId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterTypeId.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.PlanningCenterTypeId}
 */
proto.aionio.communitygroup.PlanningCenterTypeId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.PlanningCenterTypeId;
  return proto.aionio.communitygroup.PlanningCenterTypeId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.PlanningCenterTypeId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.PlanningCenterTypeId}
 */
proto.aionio.communitygroup.PlanningCenterTypeId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.PlanningCenterTypeId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.PlanningCenterTypeId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.PlanningCenterTypeId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterTypeId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterTypeId.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterTypeId} returns this
 */
proto.aionio.communitygroup.PlanningCenterTypeId.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterTypeId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterTypeId} returns this
 */
proto.aionio.communitygroup.PlanningCenterTypeId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.PlanningCenterGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.PlanningCenterGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributes: (f = msg.getAttributes()) && proto.aionio.communitygroup.PlanningCenterGroupAttributes.toObject(includeInstance, f),
    relationships: (f = msg.getRelationships()) && proto.aionio.communitygroup.PlanningCenterGroupRelationships.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup}
 */
proto.aionio.communitygroup.PlanningCenterGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.PlanningCenterGroup;
  return proto.aionio.communitygroup.PlanningCenterGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.PlanningCenterGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup}
 */
proto.aionio.communitygroup.PlanningCenterGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.aionio.communitygroup.PlanningCenterGroupAttributes;
      reader.readMessage(value,proto.aionio.communitygroup.PlanningCenterGroupAttributes.deserializeBinaryFromReader);
      msg.setAttributes(value);
      break;
    case 4:
      var value = new proto.aionio.communitygroup.PlanningCenterGroupRelationships;
      reader.readMessage(value,proto.aionio.communitygroup.PlanningCenterGroupRelationships.deserializeBinaryFromReader);
      msg.setRelationships(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.PlanningCenterGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.PlanningCenterGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributes();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.aionio.communitygroup.PlanningCenterGroupAttributes.serializeBinaryToWriter
    );
  }
  f = message.getRelationships();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.aionio.communitygroup.PlanningCenterGroupRelationships.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PlanningCenterGroupAttributes attributes = 3;
 * @return {?proto.aionio.communitygroup.PlanningCenterGroupAttributes}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.getAttributes = function() {
  return /** @type{?proto.aionio.communitygroup.PlanningCenterGroupAttributes} */ (
    jspb.Message.getWrapperField(this, proto.aionio.communitygroup.PlanningCenterGroupAttributes, 3));
};


/**
 * @param {?proto.aionio.communitygroup.PlanningCenterGroupAttributes|undefined} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup} returns this
*/
proto.aionio.communitygroup.PlanningCenterGroup.prototype.setAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.clearAttributes = function() {
  return this.setAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.hasAttributes = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PlanningCenterGroupRelationships relationships = 4;
 * @return {?proto.aionio.communitygroup.PlanningCenterGroupRelationships}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.getRelationships = function() {
  return /** @type{?proto.aionio.communitygroup.PlanningCenterGroupRelationships} */ (
    jspb.Message.getWrapperField(this, proto.aionio.communitygroup.PlanningCenterGroupRelationships, 4));
};


/**
 * @param {?proto.aionio.communitygroup.PlanningCenterGroupRelationships|undefined} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup} returns this
*/
proto.aionio.communitygroup.PlanningCenterGroup.prototype.setRelationships = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.clearRelationships = function() {
  return this.setRelationships(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.aionio.communitygroup.PlanningCenterGroup.prototype.hasRelationships = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.PlanningCenterGroupAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    archivedAt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enrollmentOpen: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    enrollmentStrategy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    eventsVisibility: jspb.Message.getFieldWithDefault(msg, 7, ""),
    headerImageMap: (f = msg.getHeaderImageMap()) ? f.toObject(includeInstance, undefined) : [],
    locationTypePreference: jspb.Message.getFieldWithDefault(msg, 9, ""),
    membershipsCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    publicChurchCenterWebUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    schedule: jspb.Message.getFieldWithDefault(msg, 13, ""),
    virtualLocationUrl: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.PlanningCenterGroupAttributes;
  return proto.aionio.communitygroup.PlanningCenterGroupAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArchivedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnrollmentOpen(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnrollmentStrategy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventsVisibility(value);
      break;
    case 8:
      var value = msg.getHeaderImageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationTypePreference(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMembershipsCount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicChurchCenterWebUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchedule(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualLocationUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.PlanningCenterGroupAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArchivedAt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnrollmentOpen();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getEnrollmentStrategy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEventsVisibility();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHeaderImageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLocationTypePreference();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMembershipsCount();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPublicChurchCenterWebUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSchedule();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getVirtualLocationUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string archived_at = 1;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getArchivedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setArchivedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contact_email = 2;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool enrollment_open = 5;
 * @return {boolean}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getEnrollmentOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setEnrollmentOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string enrollment_strategy = 6;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getEnrollmentStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setEnrollmentStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string events_visibility = 7;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getEventsVisibility = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setEventsVisibility = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * map<string, string> header_image = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getHeaderImageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.clearHeaderImageMap = function() {
  this.getHeaderImageMap().clear();
  return this;};


/**
 * optional string location_type_preference = 9;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getLocationTypePreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setLocationTypePreference = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 memberships_count = 10;
 * @return {number}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getMembershipsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setMembershipsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string public_church_center_web_url = 12;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getPublicChurchCenterWebUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setPublicChurchCenterWebUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string schedule = 13;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getSchedule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setSchedule = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string virtual_location_url = 14;
 * @return {string}
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.getVirtualLocationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupAttributes} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupAttributes.prototype.setVirtualLocationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.PlanningCenterGroupRelationships.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.PlanningCenterGroupRelationships} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupType: (f = msg.getGroupType()) && proto.aionio.communitygroup.PlanningCenterData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupRelationships}
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.PlanningCenterGroupRelationships;
  return proto.aionio.communitygroup.PlanningCenterGroupRelationships.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.PlanningCenterGroupRelationships} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupRelationships}
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.aionio.communitygroup.PlanningCenterData;
      reader.readMessage(value,proto.aionio.communitygroup.PlanningCenterData.deserializeBinaryFromReader);
      msg.setGroupType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.PlanningCenterGroupRelationships.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.PlanningCenterGroupRelationships} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.aionio.communitygroup.PlanningCenterData.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlanningCenterData group_type = 1;
 * @return {?proto.aionio.communitygroup.PlanningCenterData}
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.prototype.getGroupType = function() {
  return /** @type{?proto.aionio.communitygroup.PlanningCenterData} */ (
    jspb.Message.getWrapperField(this, proto.aionio.communitygroup.PlanningCenterData, 1));
};


/**
 * @param {?proto.aionio.communitygroup.PlanningCenterData|undefined} value
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupRelationships} returns this
*/
proto.aionio.communitygroup.PlanningCenterGroupRelationships.prototype.setGroupType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.aionio.communitygroup.PlanningCenterGroupRelationships} returns this
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.prototype.clearGroupType = function() {
  return this.setGroupType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.aionio.communitygroup.PlanningCenterGroupRelationships.prototype.hasGroupType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.JoinCommunityGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.JoinCommunityGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.aionio.communitygroup.PlanningCenterTypeId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupResponse}
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.JoinCommunityGroupResponse;
  return proto.aionio.communitygroup.JoinCommunityGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.JoinCommunityGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupResponse}
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.aionio.communitygroup.PlanningCenterTypeId;
      reader.readMessage(value,proto.aionio.communitygroup.PlanningCenterTypeId.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.JoinCommunityGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.JoinCommunityGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.aionio.communitygroup.PlanningCenterTypeId.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlanningCenterTypeId data = 1;
 * @return {?proto.aionio.communitygroup.PlanningCenterTypeId}
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.prototype.getData = function() {
  return /** @type{?proto.aionio.communitygroup.PlanningCenterTypeId} */ (
    jspb.Message.getWrapperField(this, proto.aionio.communitygroup.PlanningCenterTypeId, 1));
};


/**
 * @param {?proto.aionio.communitygroup.PlanningCenterTypeId|undefined} value
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupResponse} returns this
*/
proto.aionio.communitygroup.JoinCommunityGroupResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.aionio.communitygroup.JoinCommunityGroupResponse} returns this
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.aionio.communitygroup.JoinCommunityGroupResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.GetCommunityGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.GetCommunityGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.GetCommunityGroupsRequest}
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.GetCommunityGroupsRequest;
  return proto.aionio.communitygroup.GetCommunityGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.GetCommunityGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.GetCommunityGroupsRequest}
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.GetCommunityGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.GetCommunityGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.GetCommunityGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.aionio.communitygroup.GetCommunityGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.aionio.communitygroup.GetCommunityGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.aionio.communitygroup.PlanningCenterGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.aionio.communitygroup.GetCommunityGroupsResponse}
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.aionio.communitygroup.GetCommunityGroupsResponse;
  return proto.aionio.communitygroup.GetCommunityGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.aionio.communitygroup.GetCommunityGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.aionio.communitygroup.GetCommunityGroupsResponse}
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.aionio.communitygroup.PlanningCenterGroup;
      reader.readMessage(value,proto.aionio.communitygroup.PlanningCenterGroup.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.aionio.communitygroup.GetCommunityGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.aionio.communitygroup.GetCommunityGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.aionio.communitygroup.PlanningCenterGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlanningCenterGroup data = 1;
 * @return {!Array<!proto.aionio.communitygroup.PlanningCenterGroup>}
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.aionio.communitygroup.PlanningCenterGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.aionio.communitygroup.PlanningCenterGroup, 1));
};


/**
 * @param {!Array<!proto.aionio.communitygroup.PlanningCenterGroup>} value
 * @return {!proto.aionio.communitygroup.GetCommunityGroupsResponse} returns this
*/
proto.aionio.communitygroup.GetCommunityGroupsResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.aionio.communitygroup.PlanningCenterGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.aionio.communitygroup.PlanningCenterGroup}
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.aionio.communitygroup.PlanningCenterGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.aionio.communitygroup.GetCommunityGroupsResponse} returns this
 */
proto.aionio.communitygroup.GetCommunityGroupsResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};


goog.object.extend(exports, proto.aionio.communitygroup);
