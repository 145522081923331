import React, { Component } from 'react'
import CreateGame from './CreateGame'
import CardComponent from './CardComponent'
import {grpc} from "@improbable-eng/grpc-web";

import {GameService} from "./_proto/aionio/game/game_service_pb_service";
import {PlayerActionRequest, PlayerActionResponse} from "./_proto/aionio/game/game_service_pb";


import {BookService} from "./my-generated-code/book_service_pb_service";
import {GetBookRequest} from "./my-generated-code/book_service_pb";
import {CardService} from "./_proto/aionio/deck/card_service_pb_service";

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class PlayGame extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      url: this.props.url,
      response: 'Original',
      count: 0,
      images: [],
      hole: [],
      flop: [],
      turn: null,
      river: null,
      client: null,
      name: cookies.get('name') || 'Jeff'
    };
  }

  loadImages = images => {
    let arr = []
    let promises = []
    images.forEach(element => {
      // arr.push(`./cards/${element.toUpperCase()}.svg`)
      promises.push(import(`./cards/${element.toUpperCase()}.svg`))
      // .then(image => {
      //   arr.push(AS)
      //   console.log('image.default')
      //   console.log(AS)
      //   console.log(image)
      //   console.log(typeof image.default)
      // });
    });
    Promise.all(promises).then(values => {
      arr = values.map(value => 
        value.default
      );
      this.setState({
        images: arr
      })
    })
    console.log("in jeff loadimages")
    console.log(arr)
    console.log(typeof arr)
    console.log(typeof arr[0])
    console.log("slice")
    console.log(typeof arr.slice)
    if (this.state.count === 0) {
      console.log("updating state")
      this.setState({
        count: 1,
        foobar: arr
      });
    }
    
  }

  listImages = images => {
    images.map((image) => 
      <img src={image} alt=''/>
    )
  }

  componentDidMount() {
    // const getBookRequest = new GetBookRequest();
    // getBookRequest.setIsbn(60929871);
    // grpc.unary(BookService.GetBook, {
    //   request: getBookRequest,
    //   host: 'http://localhost:9090',
    //   transport: grpc.CrossBrowserHttpTransport({ withCredentials: 'include' }),
    //   onEnd: res => {
    //     const { status, statusMessage, headers, message, trailers } = res;
    //     if (status === grpc.Code.OK && message) {
    //       console.log("all ok. got book: ", message.toObject());
    //     }
    //   }
    // });
    const playerActionRequest = new PlayerActionRequest();
    playerActionRequest.setMessage("my name");
    const client = grpc.client(GameService.PlayerAction, {
      // host: "https://api.aion.io",
      host: "http://localhost:9090",
      transport: grpc.WebsocketTransport()
    })
    client.onMessage((message: PlayerActionResponse) => {
      console.log("onMessage", message.toObject());
      var resp = message.toObject();
      let arr = [];
      let promises = [];
      if (resp.type == "flop") {
        resp.flopList.forEach(element => {
          promises.push(import(`./cards/${element.toUpperCase()}.svg`));
        })
        Promise.all(promises).then(values => {
          arr = values.map(value => 
            value.default
          );
          this.setState({
            flop: arr
          })
        });
      } else if (resp.type == "hole") {
        resp.holeList.forEach(element => {
          promises.push(import(`./cards/${element.toUpperCase()}.svg`));
        })
        Promise.all(promises).then(values => {
          arr = values.map(value => 
            value.default
          );
          this.setState({
            hole: arr
          })
        });
      }
    });
    client.onEnd((status: grpc.Code, statusMessage: string, trailers: grpc.Metadata) => {
      console.log("onEnd", status, statusMessage, trailers);
    });
    const { cookies } = this.props;
    client.start(new grpc.Metadata({"name": cookies.get('name')}));
    client.send(playerActionRequest);
    let playerActionRequest2 = new PlayerActionRequest();
    playerActionRequest2.setMessage("first message")
    client.send(playerActionRequest2);
    this.setState({
      client: client
    });
    // fetch("https://api.aion.io")
    //   .then(response => {
    //     return response.text();
    //   })
    //   .then(data => {
    //     this.setState({
    //       response: data
    //     });
    //   },
    //   error => {
    //     this.setState({
    //       response: error
    //     })
    //   })
    // const socket = new WebSocket('wss://api.aion.io/websocket');
    // // const socket = new WebSocket('ws://localhost:9090/websocket');
    // socket.addEventListener('open', function (event) {
    //   socket.send('Hello Server!');
    // });
    // socket.addEventListener('message', (event) => {
    //   console.log('Message from server:', event.data);
    //   this.loadImages(JSON.parse(event.data))
    //   // this.setState({
    //   //   images: JSON.parse(event.data)
    //   // });
    // });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let playerActionRequest = new playerActionRequest();
      playerActionRequest.setMessage(e.target.value);
      this.state.client.send(playerActionRequest);
      const { cookies } = this.props;
      var name = e.target.value;
      cookies.set('name', name, { path: '/' });
      this.setState({ name });
    }
  }

  render() {
    // {this.state.foobar.map((image, index) =>
    //   <CardComponent src={image} key={index}/>
    // )}
    return (
      <div>
        {this.state.name && <div>Hello {this.state.name}!!</div>}
        <input type="text" onKeyDown={this._handleKeyDown} />
        <button onClick={() => this.setState({images: ['blah']})}>Ace Spades</button>
        <button onClick={() => this.setState({images: ['blah2']})}>3 Spades</button>
        {this.state.images.map((image, index) =>
          <CardComponent src={image} key={index}/>
        )}
        Hi {this.state.response}<br/>
        {this.state.count}
        <div className="container-fluid">
          <div class="circle_container col">
            <img class="transparent_square" src="http://i.stack.imgur.com/5Y4F4.jpg" width="2" height="2" />
            <div class="central_text text-center">
              <div class="d-flex justify-content-center">
                {this.state.flop.map((image, index) =>
                  <CardComponent src={image} key={index}/>
                )}
              </div>
              <h3>QuadsNow</h3>
              <h5>Only for you</h5>
            </div>

            <div class="moon_container moon1">
              <button className="btn btn-outline-secondary moon moon1" type="button">Sit Here</button>
              <div class="d-flex justify-content-center">
                {this.state.hole.map((image, index) =>
                  <CardComponent src={image} key={index} className="moon moon1 hole"/>
                )}
              </div>
            </div>
            <div class="moon_container moon2"><button className="btn btn-outline-secondary moon moon2" type="button">Sit Here</button></div>
            <div class="moon_container moon3"><button className="btn btn-outline-secondary moon moon3" type="button">Sit Here</button></div>
            <div class="moon_container moon4"><button className="btn btn-outline-secondary moon moon4" type="button">Sit Here</button></div>
            <div class="moon_container moon5"><button className="btn btn-outline-secondary moon moon5" type="button">Sit Here</button></div>
            <div class="moon_container moon6"><button className="btn btn-outline-secondary moon moon6" type="button">Sit Here</button></div>
            <div class="moon_container moon7"><button className="btn btn-outline-secondary moon moon7" type="button">Sit Here</button></div>
            <div class="moon_container moon8"><button className="btn btn-outline-secondary moon moon8" type="button">Sit Here</button></div>
          </div>
        </div>
      </div>
    )
  }
}

export default withCookies(PlayGame);