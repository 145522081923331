import React, { Component } from 'react';
import {grpc} from "@improbable-eng/grpc-web";
import {UserService} from "./_proto/aionio/user/user_service_pb_service";
import {CreateUserRequest} from "./_proto/aionio/user/user_service_pb";

export default class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      busyIndicator: false
    }
  }

  _handleChangeEmail = (e) => {
    this.setState({email: e.target.value});
  }

  _handleChangePassword = (e) => {
    this.setState({password: e.target.value});
  }

  _handleSubmit = (e) => {
    this.setState({busyIndicator: true});
    const createUserRequest = new CreateUserRequest();
    createUserRequest.setEmail(this.state.email);
    createUserRequest.setPassword(this.state.password);
    var response;
    grpc.unary(UserService.CreateUser, {
      request: createUserRequest,
      host: 'https://api.aion.io',
      // host: 'http://localhost:9090',
      transport: grpc.CrossBrowserHttpTransport({ withCredentials: 'include' }),
      onEnd: res => {
        const { status, statusMessage, headers, message, trailers } = res;
        if (status === grpc.Code.OK && message) {
          response = message.toObject()
          console.log("all ok. got resp: ", response);
          // window.location.href = response.url;
        }
      }
    });
    e.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this._handleSubmit}>
        <div>
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" value={this.state.email} onChange={this._handleChangeEmail} />
        </div>
        <div>
          <label for="password">Password</label>
          <input type="password" class="form-control" value={this.state.password} onChange={this._handleChangePassword} />
        </div>
        <button disabled={this.state.busyIndicator} type="submit" class="btn btn-primary">
          {this.state.busyIndicator ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Submit"}
        </button>
      </form>
    )
  }
}